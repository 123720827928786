.pp-input {
  padding: 0px 11px !important;
}

.pp-input > input {
  background-color: #fafafa;
  font-size: 14px !important;
  height: 42px;
}

.pp-dropdownbutton > button {
  background-color: #3f81f4;
  height: auto;
  border: none;
}

.pp-dropdownbutton.approve > button {
  background-color: #48b553 !important;
}

.pp-dropdownbutton.approve {
  padding: 0;
}

.pp-dropdownbutton.approve > button:nth-child(2) {
  background-color: #2b8134 !important;
}

.pp-dropdownbutton {
  border: none !important;
  height: auto;
  background-color: transparent !important;
}

.pp-dropdownbutton button span {
  color: var(--primary-color) !important;
}

.pp-dropdownbutton.sort-btn {
  background-color: #fff !important;
  height: 2.8rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pp-dropdownbutton.sort-btn.view-details {
  border: 1px solid #41424230 !important;
}

.ant-dropdown-menu label {
  color: #b4b4b4;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.pp-dropdownbutton button:nth-child(1) {
  border-left: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background-color: #6ae3c7;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  height: 42px;
}

.no-dd button:nth-child(1) {
  border-radius: 10px !important;
}

.pp-dropdownbutton button:nth-child(2) {
  background-color: #05d6a5;
  height: auto;
  border-right: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  width: 48px;
}

.pp-dropdownbutton button:nth-child(2)::after {
  background-color: #3f81f4 !important;
  display: none !important;
}

.pp-modal .ant-modal-body {
  padding: 0px !important;
}

.pp-icon-18 > svg {
  font-size: 18px;
}

.pp-icon-25 > svg {
  font-size: 25px;
}

.ant-dropdown-menu {
  border-radius: 10px !important;
  padding: 4px 4px !important;
}

.pp-dropdownbutton-menu button:nth-child(1) {
  display: none;
}

.pp-dropdownbutton-menu button:nth-child(2) {
  /* padding: 14px 24px; */
  height: auto;
  border-radius: 10px !important;
  width: 51px;
  height: 51px;
}

.pp-drawer .ant-drawer-body {
  padding: 0px !important;
}

.pp-drawer .ant-drawer-header {
  padding: 40px 46px 40px 76px !important;
  border: 0px;
  border-bottom: 1px solid #70707057;
}

.pp-form-input {
  border: 0px !important;
  font-size: 15px !important;
  line-height: 24px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  background-color: transparent !important;
  height: 40px;
}

.pp-form-input input {
  border: 0px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: transparent !important;
}

.pp-form-input:focus {
  border: 0px !important;
  border-bottom: 1px solid #40a9ff !important;
  box-shadow: none !important;
}

.pp-form-input input:focus {
  border: 0px !important;
  box-shadow: none !important;
}

.pp-collapse {
  border: 0px !important;
}

.pp-collapse-header {
  color: #4b5660;
  font-size: 1.2rem;
  line-height: 29px;
  font-weight: bold;
}

.pp-collapse .ant-collapse-header {
  background-color: white;
  display: flex;
  align-items: center;
}

.pp-collapse .ant-collapse-item-active {
  border-bottom: 0px !important;
}

.pp-dropdown-divider {
  margin: 0px !important;
}

.pp-dropdown {
  border-radius: 6px !important;
}

.pp-dropdown-inline {
  border: 0px !important;
}

.pp-dropdown-inline .ant-select-selector {
  border: 0px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  background-color: transparent !important;
}

.pp-dropdown > input {
  height: 100% !important;
}

.pp-dropdown .ant-select-selection-search-input {
  font-size: 15px !important;
  line-height: 21px !important;
  /* height: 54px !important; */
  height: 40px !important;
}

.pp-dropdown .ant-select-selection-search {
  font-size: 17px !important;
  line-height: 21px !important;
}

.pp-dropdown .ant-select-selection-placeholder {
  font-size: 15px !important;
  /* height: 40px !important; */
  height: 30px !important;
  vertical-align: middle;
  display: flex;
  align-content: center;
  align-items: center;
}

.pp-dropdown .ant-select-selection-item {
  /* font-size: 17px !important; */
  font-size: 15px !important;
  line-height: 21px !important;
  /* height: 54px; */
  height: 30px;
  vertical-align: middle;
  display: flex;
  align-content: center;
  align-items: center;
}

.pp-dropdown .ant-select-selector,
.pp-dropdown .ant-select-selection-placeholder,
.pp-dropdown .ant-select-selection-item {
  /* height: 54px !important; */
  height: 40px !important;
}

.pp-dropdown-menu .ant-select-item-option-content {
  font-size: 17px !important;
  line-height: 21px !important;
}

.pp-dropdown-menu .ant-select-item {
  padding: 10px 12px !important;
}

.pp-form-item {
  padding-top: 25px;
}

.pp-form-item .ant-select-disabled {
  opacity: 0.6;
}

.pp-form-item .ant-input-disabled {
  color: #212121;
  opacity: 0.6;
}

.pp-dropdown-add-new {
  color: #e0272b;
  height: fit-content;
  padding: 1rem 1.5rem;
}

.pp-dropdown-add-new > span {
  color: #e0272b;
  font-size: 15px;
  line-height: 21px;
}

.pp-main-button > span {
  margin: 0;
  font-size: 14px;
  color: var(--primary-color);
}
.pp-main-button svg {
  fill: var(--primary-color);
}

.pp-dropdownbutton .down-caret svg {
  font-size: 12px;
  fill: var(--primary-color);
}

.pp-dropdownbutton-menu .ant-btn-icon-only:hover {
  border: 1px solid #d9d9d9;
}

.pp-dropdownbutton-menu .ant-btn:hover {
  color: #262626;
}

.sub-menu-selected.has-children span {
  color: #3f81f4;
}

.pp-main-button {
  border-radius: 10px !important;
  background-color: #6ae3c7 !important;
  padding: 10px 15px !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  height: 42px !important;
  min-width: 100px;
  border: none;
  font-weight: 500;
}

.pp-secondary-button {
  border-color: #3f81f4 !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.pp-secondary-button.small {
  height: 32px !important;
}

.pp-secondary-button > span {
  margin: 0;
  font-weight: "medium";
  font-size: 14px;
  color: #3f81f4;
}

.pp-icon-button {
  border: 0 !important;
  box-shadow: none !important;
  height: fit-content !important;
  width: fit-content !important;
  min-width: unset !important;
  padding: 0px !important;
}

.table-due-date {
  color: #c14b4b;
}

.table-pending {
  color: #f6a907;
}

.table-pending:before {
  content: "• ";
}

.upload-field .title {
  color: #454545;
  font-size: 14px;
  line-height: 24px;
}

.upload-field .desc {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}

.subdesc {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  line-height: 18px;
  margin-top: 15px;
  display: inline-block;
}

.upload-field .upload-filename {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  font-size: 15px;
  height: 52px !important;
  line-height: 18px;
  padding: 0px 20px;
  flex-grow: 0.7;
}

.field-upload {
  align-items: center;
  display: flex;
}

.field-upload .btn-upload {
  align-items: center;
  border: 1px solid #3f81f4;
  background-color: #3f81f4 !important;
  border-radius: 0px 10px 10px 0px;
  color: #ffffff !important;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  height: 52px;
  padding: 0px 20px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #3f81f4;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  padding: 0.5rem;
}

.inputfile + label {
  cursor: pointer;
}

.btn-delete-file {
  color: #3f81f4;
  font-size: 1rem;
  font-weight: 500;
}

.download-file-base {
  border: 2px solid #3f81f4;
  color: #3f81f4;
}

.pp-tabs .ant-tabs-tab-btn {
  font-size: 18px;
  line-height: 10px;
  color: #4d4d4d;
}

.pp-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3f81f4;
}

.pp-tabs .ant-tabs-nav::before {
  border: 0px !important;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.fw-normal {
  font-weight: normal;
}

.fw-bold {
  font-weight: bold;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs36 {
  font-size: 36px;
}

.lh20 {
  line-height: 20px;
}

.lh22 {
  line-height: 22px;
}

.lh23 {
  line-height: 23px;
}

.lh24 {
  line-height: 24px;
}

.lh28 {
  line-height: 28px;
}

.lh44 {
  line-height: 44px;
}

.lh45 {
  line-height: 45px;
}

.color-ffffff {
  color: #ffffff;
}

.color-3C4853 {
  color: #3c4853;
}

.color-8B8B8B {
  color: #8b8b8b;
}

.color-454545 {
  color: #454545;
}

.color-B4B4B4 {
  color: #b4b4b4;
}

.color-4B5660 {
  color: #4b5660;
}

.color-818181 {
  color: #818181;
}

.color-333333 {
  color: #333333;
}

.flex {
  display: flex;
}

.fl-column {
  flex-direction: column;
}

.fl-row {
  flex-direction: row;
}

.no-wrap {
  white-space: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.no-filters {
  color: #7676764f !important;
  font-size: 1rem;
}

.menu-label {
  font-size: 1rem;
  color: #454545;
}

.table-menu-item {
  padding: 10px 0;
}

.pp-icon-18 > svg {
  margin-top: 5px;
}

.pp-dropdownbutton-menu .ant-btn.ant-btn-icon-only {
  height: 42px !important;
  width: 42px !important;
}

.add-modal .title {
  font-size: 1.5rem;
  color: #4b5660;
  font-weight: bold;
  line-height: 40px;
}

.add-modal .sub-title {
  font-size: 0.9rem;
  color: #818181;
  font-weight: medium;
}

.add-modal .ant-modal-body {
  padding-top: 0;
}

.additional-div {
  padding-top: 20px;
}

.additional-div .bg {
  background-color: #e4e4e4;
  border: 1px solid #eaeaea;
  padding: 20px 16px;
}

.additional-div .bg span {
  font-size: 1rem;
  color: #333;
}

.collapse-div {
  padding: 10px 20px;
}

.add-modal .ant-select-selector {
  height: 40px !important;
}

.add-modal .ant-select-selection-item,
.add-modal .ant-select-selector input {
  height: 40px !important;
}

.add-modal .ant-select-item-option-content,
.add-modal .ant-select-selection-item {
  font-size: 14px !important;
}

form .info {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.pp-dropdownbutton .ant-btn:empty {
  display: none !important;
}

/* .pp-dropdownbutton .ant-btn {
  border-radius: 10px;
} */
.line-head label {
  color: #212121;
  font-size: 1.5rem;
  font-weight: 500;
}

.line-head {
  border-bottom: 1px solid #cecece;
  margin-bottom: 1.5rem;
  padding-bottom: .5rem;
  padding-top: .7rem;
}

.pp-form-radio {
  width: 16px !important;
  padding-top: 5px;
  cursor: pointer;
}

.pp-form-radio:focus {
  box-shadow: none !important;
}

.ant-select-selection-item p p {
  display: none;
}

.ant-select-selection-item p {
  margin-bottom: 0;
}

.pp-approve-button {
  border-color: #d6d6d6 !important;
  border-radius: 10px !important;
  background-color: #48b553 !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.pp-approve-button > span {
  margin: 0;
  font-weight: "medium";
  font-size: 14px;
  color: #ffffff;
}

.pp-reject-button {
  border-color: #d6d6d6 !important;
  border-radius: 10px !important;
  background-color: #efa42c !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.pp-reject-button > span {
  margin: 0;
  font-weight: "medium";
  font-size: 14px;
  color: #ffffff;
}

.icon-warning {
  color: #efa42c !important;
  font-size: 40px;
}

/* view Details css start  */

.upload_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 102px;
  height: 102px;
  margin-inline-end: 8px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.3s;
  cursor: pointer;
}

.upload_div:hover {
  border-color: #3f81f4;
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 102px;
  height: 102px;
  margin-inline-end: 8px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}

.avatar-uploader:hover {
  transform: scale(1.1);
}

.avatar-uploader div.ant-upload {
  width: 100%;
  height: 100%;
}

.ant-space-compact-block {
  width: fit-content !important;
}

.activate-portal[disabled] {
  border-color: #1890ff !important;
  background: #1890ff !important;
}
