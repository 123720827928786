.left-menu {
  margin: 10px;
  border-radius: 10px;
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
  margin-right: 0 !important;
}

.sub-menu {
  margin: 10px !important;
  margin-left: 0 !important;
  border-radius: 10px;
  flex: 0 0 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
  width: 230px !important;
  /* background-color: #fff; */
}

.sub-menu.collapse {
  background-color: #fff;
  margin-left: 15px !important;
}

.sub-menu h4 {
  color: #d8d8d8;
  font-size: 1.5rem;
  border-bottom: 1px solid #d8d8d880;
  padding-bottom: 0.3rem;
  margin: 1rem 1.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  position: sticky;
  top: 15px;
  z-index: 5;
  background-color: #fff;
}

.ant-layout-sider-collapsed.left-menu {
  flex: 0 0 75px !important;
  max-width: 75px !important;
  min-width: 75px !important;
  width: 75px !important;
}

.left-menu .ant-layout-sider-children,
.sub-menu .ant-layout-sider-children {
  border-radius: 10px;
}

.left-menu .ant-layout-sider-children,
.left-menu .ant-menu {
  background: #122549 !important;
}

.ant-layout-sider {
  background: transparent !important;
}

.left-menu .ant-layout-sider-children {
  top: 10px;
  position: sticky;
  height: 97.5vh;
  z-index: 1000;
}

.sub-menu {
  top: 10px;
  position: sticky;
  height: 97.5vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-label {
  font-weight: 500;
  color: #ffffff;
}

.left-menu .ant-menu {
  width: 100%;
  margin: auto;
  margin-top: 20px !important;
}

.left-menu .ant-menu-item {
  padding: 0 !important;
  padding-left: 20px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.icon {
  margin-right: 1.3rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
img.logo {
  height: 50px;
  /* position: absolute; */
  /* left: 8%;
  top: 8%; */
}
.logo img {
  width: 50%;
  position: relative;
  left: 0px;
  margin-bottom: 10px;
}

.menu-item {
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1rem;
  position: relative;
  width: 83%;
}

.left-menu .menu-selected {
  background: #6ae3c7 !important;
  border-radius: 10px;
  color: var(--primary-color) !important;
  height: 45px;
}

.left-menu .menu-selected .nav-label {
  color: var(--primary-color) !important;
}
.svg {
  transition: all 0.3s ease;
  width: 15px;
}
.svg.rotate {
  transform: rotate(180deg);
  font-weight: 600;
}

.menu-collapsed .menu-selected {
  width: 50px;
}

.menu-collapsed .logo img {
  width: 40%;
  margin-bottom: 20px;
  left: 0;
}

.menu-collapsed .icon {
  margin-right: 0;
}

.menu-collapsed .menu-item {
  justify-content: center;
  padding-left: 0;
}

.menu-collapsed .menu-selected {
  margin-bottom: 1.3rem;
}

.sub-menu-item .nav-label,
.sub-menu-children .nav-label {
  color: #363636;
  opacity: 0.5;
  display: block;
  height: fit-content;
  padding: 0 2rem;
  padding-bottom: 0.5rem;
}

.site-layout.main-content {
  margin: 20px 30px;
  width: 60%;
}

.swiper {
  max-width: 550px !important;
  width: 550px !important;
}

.header-right {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 1rem;
}

.sub-menu-item {
  margin: 0 1rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sub-menu-item .icon {
  position: relative;
  left: 1.3rem;
  top: 1px;
}

.sub-menu-children {
  position: relative;
  left: 1.4rem;
}

.sub-menu-children::before {
  content: "";
  position: absolute;
  left: 5px;
  height: 95%;
  width: 1.5px;
  background-color: #70707048;
}

.sub-menu-children-item {
  margin-bottom: 0.7rem;
  cursor: pointer;
}

.sub-menu-selected {
  background: #6ae3c735;
  border-radius: 7px;
  padding: 0.5rem 0;
}

.titleLabel {
  color: #d8d8d8;
  font-size: 1.5rem;
  border-bottom: 1px solid #d8d8d880;
  padding-bottom: 0.3rem;
  margin: 1rem 1.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.submenu .collapse.view {
  max-height: 500px;
}

.submenu-wrap,
.submenu .collapse {
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  cursor: pointer;
}

.submenu-wrap.open {
  max-height: 500px;
  margin-top: 10px;
}

.submenu {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem !important;
  /* gap: 1rem; */
}

.submenu li {
  height: 30px;
  text-align: left;
  margin-left: 5rem;
  cursor: pointer;
  margin-bottom: 0.8rem;
}

.sub-menu-selected,
.submenu-item-selected {
  background: #6ae3c7 !important;
  border-radius: 7px;
  padding: 0.5rem 0;
  color: var(--primary-color);
  font-weight: 600;
}
.submenu-item-selected {
  padding-left: 1rem;
  height: 40px !important;
  display: inline-block;
  position: relative;
  left: -1rem;
}

.submenu-item-selected,
.submenu li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sub-menu-item-1 {
  width: 100%;
  height: 1.5rem;
  position: relative;
}

.sub-menu-item-1 .titleLabel {
  border-bottom: none;
  margin: 0;
  position: relative;
  left: 1rem;
  top: 0.6rem;
  margin-bottom: 1rem;
}

.sub-menu-selected .nav-label {
  opacity: 1;
  padding: 0.3rem 1rem;
}

.sub-menu-item .nav-label {
  padding: 0.3rem 1rem;
  font-size: 13px;
}

.sub-menu-children-item .nav-label {
  font-size: 12px;
}

.sub-menu-item.has-children {
  background: transparent;
}

.sub-menu-children-item.sub-menu-selected {
  left: 1.5rem;
  position: relative;
  width: 75%;
}

.menu-item {
  position: relative;
}

.side-nav-hover {
  position: absolute;
  z-index: 1000;
  left: 0;
  padding: 1.5rem;
  background-color: #122549;
  width: 12rem;
  transform: scale(0);
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
}

.menu-item:hover .side-nav-hover {
  transform: scale(1);
  transition: all 300ms;
  left: 5.5rem;
}

.side-nav-hover .dim-text {
  font-size: 0.9rem;
  color: #fff;
  opacity: 0.5;
  display: block;
  margin-top: 1.5rem;
  line-height: 1.2rem;
  text-align: center;
}

.side-nav-hover .icon {
  margin-bottom: 1.2rem;
  background: #ffffff14;
  border-radius: 34px;
  padding: 0.7rem 1.5rem;
}

.side-nav-hover::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #122549;
  left: -8px;
}
